import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1fdc192e = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _2c5e12ea = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _bbec109a = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _0fc8bc3a = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _5411b02e = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _291f56b9 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _538d1358 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _6339a542 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _9055f10a = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _b13a93cc = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _a9466252 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _9d6450fe = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _7c18b9c2 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _13245546 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _5201eb6c = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _8f54b42e = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _c3eaeb7a = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _5fe4d4a0 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _25dd84de = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _dc10b96e = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _f936f612 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _6cb42eb8 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _7087e127 = () => interopDefault(import('../pages/onboarding/confirmation.vue' /* webpackChunkName: "pages/onboarding/confirmation" */))
const _d2172426 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _cc808fa8 = () => interopDefault(import('../pages/onboarding/enhanced-quotes.vue' /* webpackChunkName: "pages/onboarding/enhanced-quotes" */))
const _93956914 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _e8913c32 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _0da9db22 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _dae9f4b0 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/index" */))
const _3f1d1db2 = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _55637f08 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _3036f683 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _36371e7e = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _4362e576 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _34ae57b8 = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _67b94b99 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _2c1771ef = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _1985fdcb = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _20635401 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _ebeb07bc = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _459a7cf8 = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _b1782354 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _2ebfc672 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _66fe6f60 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _7542c3f7 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _f405f7b4 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _1620a0a2 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/index" */))
const _9a727a64 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/index" */))
const _2aae3d1b = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/index" */))
const _83528f58 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bathing-and-showering.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bathing-and-showering" */))
const _d9a64f38 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bladder-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bladder-control" */))
const _f634505e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bowel-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bowel-control" */))
const _572d9a2b = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/dressing.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/dressing" */))
const _3a608654 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/feeding.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/feeding" */))
const _030c0cbe = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/mobility-indoors.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/mobility-indoors" */))
const _603e1b38 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/progression-over-time.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/progression-over-time" */))
const _130686b8 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/review" */))
const _4453b47b = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/transferring.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/transferring" */))
const _26ca8a0a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/alcohol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/alcohol" */))
const _3f641ee4 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/general" */))
const _0b24aa0c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/review" */))
const _19c3fa68 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/smoking.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/smoking" */))
const _7217dc87 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/cancer.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/cancer" */))
const _7470b26c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/diabetes.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/diabetes" */))
const _5b5639f1 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/general" */))
const _35d730aa = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol" */))
const _ad9e17a6 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/hypertension.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/hypertension" */))
const _5171633e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis" */))
const _63f061d6 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/neurological.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/neurological" */))
const _dfa70d42 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/review" */))
const _7ef325cf = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _245eef50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _1fdc192e,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _2c5e12ea,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _bbec109a,
    name: "help"
  }, {
    path: "/verify-email",
    component: _0fc8bc3a,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _5411b02e,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _291f56b9,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _538d1358,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _6339a542,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _9055f10a,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _b13a93cc,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _a9466252,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _9d6450fe,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _7c18b9c2,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _13245546,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _5201eb6c,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _8f54b42e,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _c3eaeb7a,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _5fe4d4a0,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _25dd84de,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _dc10b96e,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _f936f612,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _6cb42eb8,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/confirmation",
    component: _7087e127,
    name: "onboarding-confirmation"
  }, {
    path: "/onboarding/employment-info",
    component: _d2172426,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/enhanced-quotes",
    component: _cc808fa8,
    name: "onboarding-enhanced-quotes"
  }, {
    path: "/onboarding/fund-selection",
    component: _93956914,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _e8913c32,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _0da9db22,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/medical-questionnaire",
    component: _dae9f4b0,
    name: "onboarding-medical-questionnaire"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _3f1d1db2,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _55637f08,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _3036f683,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _36371e7e,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _4362e576,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _34ae57b8,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _67b94b99,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _2c1771ef,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _1985fdcb,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _20635401,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _ebeb07bc,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _459a7cf8,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _b1782354,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _2ebfc672,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _66fe6f60,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _7542c3f7,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _f405f7b4,
    name: "personal-retirement"
  }, {
    path: "/onboarding/medical-questionnaire/ADL",
    component: _1620a0a2,
    name: "onboarding-medical-questionnaire-ADL"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle",
    component: _9a727a64,
    name: "onboarding-medical-questionnaire-lifestyle"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment",
    component: _2aae3d1b,
    name: "onboarding-medical-questionnaire-medical-assessment"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bathing-and-showering",
    component: _83528f58,
    name: "onboarding-medical-questionnaire-ADL-bathing-and-showering"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bladder-control",
    component: _d9a64f38,
    name: "onboarding-medical-questionnaire-ADL-bladder-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bowel-control",
    component: _f634505e,
    name: "onboarding-medical-questionnaire-ADL-bowel-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/dressing",
    component: _572d9a2b,
    name: "onboarding-medical-questionnaire-ADL-dressing"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/feeding",
    component: _3a608654,
    name: "onboarding-medical-questionnaire-ADL-feeding"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/mobility-indoors",
    component: _030c0cbe,
    name: "onboarding-medical-questionnaire-ADL-mobility-indoors"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/progression-over-time",
    component: _603e1b38,
    name: "onboarding-medical-questionnaire-ADL-progression-over-time"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/review",
    component: _130686b8,
    name: "onboarding-medical-questionnaire-ADL-review"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/transferring",
    component: _4453b47b,
    name: "onboarding-medical-questionnaire-ADL-transferring"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/alcohol",
    component: _26ca8a0a,
    name: "onboarding-medical-questionnaire-lifestyle-alcohol"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/general",
    component: _3f641ee4,
    name: "onboarding-medical-questionnaire-lifestyle-general"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/review",
    component: _0b24aa0c,
    name: "onboarding-medical-questionnaire-lifestyle-review"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/smoking",
    component: _19c3fa68,
    name: "onboarding-medical-questionnaire-lifestyle-smoking"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/cancer",
    component: _7217dc87,
    name: "onboarding-medical-questionnaire-medical-assessment-cancer"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/diabetes",
    component: _7470b26c,
    name: "onboarding-medical-questionnaire-medical-assessment-diabetes"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/general",
    component: _5b5639f1,
    name: "onboarding-medical-questionnaire-medical-assessment-general"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/high-cholesterol",
    component: _35d730aa,
    name: "onboarding-medical-questionnaire-medical-assessment-high-cholesterol"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/hypertension",
    component: _ad9e17a6,
    name: "onboarding-medical-questionnaire-medical-assessment-hypertension"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis",
    component: _5171633e,
    name: "onboarding-medical-questionnaire-medical-assessment-multiple-sclerosis"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/neurological",
    component: _63f061d6,
    name: "onboarding-medical-questionnaire-medical-assessment-neurological"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/review",
    component: _dfa70d42,
    name: "onboarding-medical-questionnaire-medical-assessment-review"
  }, {
    path: "/blog/:slug?",
    component: _7ef325cf,
    name: "blog-slug"
  }, {
    path: "/",
    component: _245eef50,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
